import { IS_DEV } from "@/__main__/constants.mjs";
import { setFeatureFlag } from "@/app/actions.mjs";
import { refs__PRIVATE } from "@/feature-analytics/tracker.mjs";
import { devDebug } from "@/util/dev.mjs";

const filteredEvents = [
  "PAGE_VIEW",
  "SESSION",
  "MOUSEOVER",
  "RESOURCE_USAGE",
  "RESOURCE_USAGE_RAM_ARRAY",
  "SCROLLING",
];

let timer;
let eventMap = {};
function logFilteredEvents() {
  if (timer) clearTimeout(timer);
  timer = setTimeout(logFilteredEvents, 60 * 1000);
  if (!Object.values(eventMap).length) return;

  devDebug(`[ANALYTICS]: filtered events counts`, eventMap);
  eventMap = {};
}

function logEvents(eventName, eventData, anonymous = false) {
  if (filteredEvents.includes(eventName)) {
    eventMap[eventName] = (eventMap[eventName] ?? 0) + 1;
    return Promise.resolve(true);
  }

  devDebug(`[ANALYTICS]: "${eventName}"`, { eventData, anonymous });
  return Promise.resolve(true);
}

let originalFunc;
export function setup() {
  if (!IS_DEV) return;

  originalFunc = refs__PRIVATE.trackEvent;
  refs__PRIVATE.trackEvent = logEvents;
  setFeatureFlag("analytics", true);

  logFilteredEvents();
}

export function teardown() {
  if (!IS_DEV) return;

  setFeatureFlag("analytics", false);
  refs__PRIVATE.trackEvent = originalFunc;

  clearTimeout(timer);
}
